import React, { useRef } from "react";

export default function DeliveryInformation(props) {
  const addressRef = useRef();
  const address2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipCodeRef = useRef();

  function updateInfo() {
    props.setDeliveryInfo({
      address: addressRef.current.value,
      address2: address2Ref.current.value,
      city: cityRef.current.value,
      state: stateRef.current.value,
      zipCode: zipCodeRef.current.value,
    });
  }

  return (
    <div>
      <h2>Delivery Information</h2>

      <input
        ref={addressRef}
        onChange={updateInfo}
        required
        name="address"
        type="text"
        placeholder="Address"
      />
      <input
        ref={address2Ref}
        onChange={updateInfo}
        name="address-2"
        type="text"
        placeholder="Address Line 2 (Optional)"
      />
      <input
        ref={cityRef}
        onChange={updateInfo}
        required
        name="city"
        type="text"
        placeholder="City"
      />
      <input
        ref={stateRef}
        onChange={updateInfo}
        required
        name="state"
        type="text"
        placeholder="State"
      />
      <input
        ref={zipCodeRef}
        onChange={updateInfo}
        required
        name="zip-code"
        type="text"
        placeholder="Zip Code"
      />
    </div>
  );
}
