// packages
import { useState } from "react";
import { AdminContext } from "./AdminContext";

// components
import Console from "./Console";
import Login from "./Login";

export default function Admin() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");

  return (
    <div className="admin-page min-h-screen bg-slate-950">
      <AdminContext.Provider
        value={{
          loginPair: { loggedIn, setLoggedIn },
          usernamePair: { username, setUsername },
        }}
      >
        <div className="bg-slate-200 text-slate-900">
          {loggedIn ? <Console /> : <Login />}
        </div>
        <div className="mt-16 w-full bg-slate-950 px-16 py-10 pb-28 text-center text-slate-600">
          So-Called Civilized Admin Console &copy; 2024
        </div>
      </AdminContext.Provider>
    </div>
  );
}
