import React, { useContext } from "react";
import { CartContext } from "@context/CartContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SwalUtils from "@components/SwalUtils";

export default function CartReview() {
  const { cart } = useContext(CartContext);

  return (
    <>
      {cart.length === 0 && (
        <>
          <h1>Hmmmm.....</h1>
          <p>
            Your cart is empty. Find something in the{" "}
            <Link to="/shop">shop</Link>!
          </p>
          <p>
            <a href="mailto:support@so-calledcivilized.com?bcc=scc.web.control@gmail.com&subject=Issue%20with%20SCC%20Website">
              Did something go wrong?
            </a>
          </p>
        </>
      )}
      {cart.length !== 0 ? (
        <>
          <form>
            <div>
              <div>
                <h2>Review your Cart</h2>
                <div className="cart-items">
                  {cart.map((item, key) => {
                    return <CartItem item={item} key={key} />;
                  })}
                </div>
              </div>
            </div>
          </form>
        </>
      ) : null}
    </>
  );
}

function CartItem(props) {
  const { setCart } = useContext(CartContext);
  const { item } = props;

  return (
    <div className="item-card box">
      <img
        src={item.product.fields.itemImages[0].fields.file.url}
        alt={item.product.fields.itemName}
        draggable="false"
      />
      <div className="item-details">
        <span className="name">{item.product.fields.itemName}</span>
        <span className="type">{item.product.fields.itemType}</span>
        <span className="price">
          <b>Price:</b> ${item.product.fields.price.toFixed(2)}
        </span>
        <span className="quantity">
          <b>Quantity:</b> {item.quantity}
        </span>
        {item.size && (
          <span className="size">
            <b>Size:</b> {item.size}
          </span>
        )}
      </div>
      <div className="item-actions">
        <span
          onClick={() => {
            Swal.fire({
              ...SwalUtils.universal,
              ...SwalUtils.types["deletePrompt"],
              ...{
                title:
                  "Are you sure you want to remove this item from your cart?",
                text: "This cannot be undone.",
                icon: "warning",
                showCancelButton: true,
              },
            }).then(async (deleteItem) => {
              if (deleteItem.isConfirmed) {
                setCart((cart) => {
                  return cart.filter(
                    (i) => i.product.sys.id !== item.product.sys.id
                  );
                });
              }
            });
          }}
          className="item-actions">
          Remove from Cart
        </span>
      </div>
    </div>
  );
}
