// packages
import React, { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ContentfulContext } from "@context/ContentfulContext";

// styling
import "@css/Post.css";

// components
import ProductCard from "@components/ProductCard";

// images/icons
import BackArrow from "@images/icons/back-arrow.svg";

export default function Post() {
  const contentfulClient = useContext(ContentfulContext);
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const { postID } = useParams();

  useEffect(() => {
    contentfulClient
      .getEntry(postID)
      .then((response) => {
        setPost(response);
      })
      .catch((err) => {
        console.log(err);
        setError("Post does not exist or could not be loaded.");
      });
  }, [postID, contentfulClient]);

  if (error !== null) {
    return (
      <>
        <h1>Error</h1>
        <p>{error}</p>
      </>
    );
  }

  if (post === null) {
    return <h1>Loading post...</h1>;
  }

  return (
    <div className="post">
      <Link className="back-button" to="/blog">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="mr-1 size-8"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
          />
        </svg>
        Back
      </Link>
      <div className="post-container">
        <img
          src={post.fields.thumbnail.fields.file.url}
          loading="lazy"
          draggable="false"
          className="thumbnail"
          alt="Post Thumbnail"
        />
        <div className="post-content">
          <h1 className="title text-4xl">{post.fields.postTitle}</h1>
          <span className="date-posted">
            {new Date(post.sys.createdAt).toLocaleDateString()}
          </span>
          <p className="body">{post.fields.postContent}</p>
          {post.fields.relatedShopItem ? (
            <div className="related-items">
              <h2>Buy it here!</h2>
              <ProductCard product={post.fields.relatedShopItem[0]} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
