// packagesstyling
import React, { useContext, useEffect, useState } from "react";
import { ContentfulContext } from "@context/ContentfulContext";
import Swal from "sweetalert2";
import SwalUtils from "@components/SwalUtils";

// styling
import "@css/Shop.css";

// components
import Hero from "@components/Hero";
import ShopFilter from "@components/ShopFilter";
import ProductCard from "@components/ProductCard";

// images/icons
import ChevronLeft from "@images/icons/chevron-left.svg";
import ChevronRight from "@images/icons/chevron-right.svg";

export default function Shop() {
  const contentfulClient = useContext(ContentfulContext);
  const [hero, setHero] = useState(null);
  const [products, setProducts] = useState(null);
  const [itemPreviewShowing, setItemPreviewShowing] = useState(false);
  const [itemPreviewImage, setItemPreviewImage] = useState(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [filterArray, setFilterArray] = useState([]);

  /*
  Function: getProducts
  Description: Gets products from contentful and returns them as an array.
  Parameters:
    itemTypeFilter (string array/optional): Allows for filtering of products by item type. If empty, all products are returned.
    nameFilter (string/optional): Allows for filtering of products by name. If empty, all products are returned.
  Returns: Array of products.
  */
  async function getProducts(itemTypeFilter, nameFilter) {
    setProducts(null);
    setPage(1);
    setNumPages(1);

    let searchQuery;
    if (itemTypeFilter == null || itemTypeFilter.length === 0) {
      searchQuery = {
        content_type: "shopItem",
        order: "-sys.updatedAt",
        limit: 1000,
      };
    } else {
      searchQuery = {
        content_type: "shopItem",
        "fields.itemType[in]": itemTypeFilter.join(","),
        order: "-sys.updatedAt",
        limit: 1000,
      };
    }

    // get product information
    contentfulClient
      .getEntries(searchQuery)
      .then((response) => {
        setProducts(response.items);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong on our end. Please try again later, sorry!",
          icon: "error",
        });
        console.log(err);
      });
  }

  useEffect(() => {
    // get hero information
    //  NOTE - this used to be called "banner"
    contentfulClient
      .getEntries({ content_type: "bannerContent" })
      .then((response) => {
        response.items.forEach((item) => {
          if (item.fields.page === "Shop") {
            setHero(item.fields);
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          ...SwalUtils.universal,
          ...{
            title: "Error",
            text: "Could not connect to content repository. Please try again later. Error printed in console.",
            icon: "error",
          },
        });
        console.log(err);
      });

    getProducts();
  }, []);

  useEffect(() => {
    if (products != null) {
      setNumPages(Math.ceil(products.length / 15));
    }
  }, [products]);

  return (
    <div className="shop">
      <div
        onClick={() => {
          setItemPreviewShowing(false);
          setItemPreviewImage(null);
        }}
        className={"item-preview " + (itemPreviewShowing ? "" : "hidden")}
      >
        <img src={itemPreviewImage} alt="Item preview" draggable="false" />
      </div>
      {
        // avoid page break when banner not set yet
        hero === null ? null : (
          <Hero
            backgroundImage={hero.backgroundImage.fields.file.url}
            headline={hero.mainHeadline}
            subheadline={hero.subheadline}
          />
        )
      }
      <div className="shop-container">
        {/* <ShopFilter
          getProducts={getProducts}
          filterArray={filterArray}
          setFilterArray={setFilterArray}
        /> */}
        <div className="products-container">
          {products === null ? (
            <h1>Loading products...</h1>
          ) : (
            products.map((product, key) => {
              // hide all items not on the current page
              if (
                products.indexOf(product) < (page - 1) * 15 ||
                products.indexOf(product) > (page - 1) * 15 + 14
              ) {
                return null;
              }

              return (
                <ProductCard
                  key={key}
                  setItemPreviewShowing={setItemPreviewShowing}
                  setItemPreviewImage={setItemPreviewImage}
                  product={product}
                />
              );
            })
          )}
          {products != null && products.length === 0 ? (
            <h1>No items match this criteria.</h1>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="page-selector">
        <button
          disabled={page === 1}
          onClick={() => {
            if (page > 1) {
              setPage(page - 1);
              window.scrollTo(0, 0);
            }
          }}
        >
          <img src={ChevronLeft} alt="Previous Page" />
        </button>
        <span>
          Page {page} of {numPages}
        </span>
        <button
          disabled={page === numPages}
          onClick={() => {
            if (products && products.length > page * 15) {
              setPage(page + 1);
              window.scrollTo(0, 0);
            }
          }}
        >
          <img src={ChevronRight} alt="Next Page" />
        </button>
      </div>
    </div>
  );
}
