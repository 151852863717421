import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "@context/CartContext";

// styling
import "./Checkout.css";

// components
import CartReview from "./Partials/CartReview";
import ContactInformation from "./Partials/ContactInformation";
import DeliveryInformation from "./Partials/DeliveryInformation";
import CartTotal from "./Partials/CartTotal";

export default function Checkout() {
  const [checkoutSuccess, setCheckoutSuccess] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const { cart } = useContext(CartContext);

  useEffect(() => {
    // check for checkout result
    if (window.location.search !== "") {
      const queryParameters = new URLSearchParams(window.location.search);
      const success = queryParameters.get("success");

      if (success === "true") {
        setCheckoutSuccess(true);
      } else {
        console.log("Checkout failure");
        setCheckoutSuccess(false);
      }
    }
  }, []);

  return (
    <div className="checkout">
      {checkoutSuccess === null ? (
        <>
          {currentStep === 0 && <CartReview />}
          {currentStep === 1 && (
            <ContactInformation setCustomerInfo={setCustomerInfo} />
          )}
          {currentStep === 2 && (
            <DeliveryInformation setDeliveryInfo={setDeliveryInfo} />
          )}
          {currentStep === 3 && (
            <CartTotal
              customerInfo={customerInfo}
              deliveryInfo={deliveryInfo}
            />
          )}
          {cart.length !== 0 && currentStep > 0 && (
            <button onClick={() => setCurrentStep(currentStep - 1)}>
              Back
            </button>
          )}
          {cart.length !== 0 && currentStep < 3 && (
            <button onClick={() => setCurrentStep(currentStep + 1)}>
              Next
            </button>
          )}
        </>
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={
              "m-auto mt-10 size-24 " +
              (checkoutSuccess ? "text-blue-500" : "text-red-500")
            }
          >
            {checkoutSuccess ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            )}
          </svg>
          <h1 className="my-3 text-3xl">
            Checkout {checkoutSuccess ? "Success" : "Failure"}
          </h1>
          {checkoutSuccess ? (
            <>
              <p>
                Thank you - we'll be sure to fulfill your order as soon as
                possible.
              </p>
              <p>
                While you wait, check out our{" "}
                <a
                  href="https://www.instagram.com/socalledcivilized"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Instagram
                </a>
                !
              </p>
            </>
          ) : (
            <p>
              There was an issue completing your request. Please try again and
              if you encounter more issues, email us at{" "}
              <a
                className="text-blue-500 hover:underline"
                href="mailto:support@so-calledcivilized.com"
              >
                support@so-calledcivilized.com
              </a>
              .
            </p>
          )}
        </>
      )}
    </div>
  );
}
