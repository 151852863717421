import React from "react";

import "@css/Hero.css";

export default function Hero(props) {
  const { headline, subheadline, backgroundImage, size } = props;

  return (
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className="hero">
      <div className="hero-content">
        <span className="headline">{headline}</span>
        <span className="subheadline">{subheadline}</span>
      </div>
    </div>
  );
}
