class SwalUtils {
  static universal = {
    backdrop: true,
    background: "#222222",
    color: "#f2f2f2",
    iconColor: "#704FF6",
  };

  static types = {
    generalAlert: {
      showConfirmButton: false,
    },
    newsletterSignup: {
      confirmButtonText: "Sign Up",
      cancelButtonText: "No Thanks",
      showCancelButton: true,
      input: "email",
      inputPlaceholder: "Your Email",
      inputAutoFocus: true,
    },
    confirm: {
      showConfirmButton: true,
      showCancelButton: true,
    },
    deletePrompt: {
      confirmButtonColor: "#ed4040",
      confirmButtonText: "Remove",
    },
  };
}

export default SwalUtils;
