// packages
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentfulContext } from "@context/ContentfulContext";
import Swal from "sweetalert2";
import SwalUtils from "@components/SwalUtils";

// styling
import "@css/Footer.css";

// images/icons
import LargeLogoWhite from "@images/branding/large-logo-white.png";
import Phone from "@images/icons/phone.svg";
import Email from "@images/icons/mail.svg";

export default function Footer() {
  const contentfulClient = useContext(ContentfulContext);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);

  useEffect(() => {
    contentfulClient
      .getEntries({ content_type: "generalInformation" })
      .then((res) => {
        setPhoneNumber(res.items[0].fields.phoneNumber);
        setEmailAddress(res.items[0].fields.emailAddress);
      })
      .catch((err) => {
        Swal.fire({
          ...SwalUtils.universal,
          ...{
            title: "Error",
            text: "Could not connect to content repository. Please try again later. Error printed in console.",
            icon: "error",
          },
        });
        console.log(err);
      });
  }, [contentfulClient]);

  return (
    <div className="footer mt-24 border-t-4 border-indigo-500 bg-black">
      <div className="logo">
        <img
          draggable="false"
          loading="lazy"
          src={LargeLogoWhite}
          alt="Small Logo"
        />
      </div>
      <div>
        <ul>
          <li>Information</li>
          <li>
            <Link to="/about">About us</Link>
          </li>
        </ul>
        <ul>
          <li>Legal</li>
          <li>
            <Link to="/legal/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/legal/terms-of-service">Terms of Service</Link>
          </li>
          <li>
            <Link to="/legal/content-disclosure">Content Disclosure</Link>
          </li>
        </ul>
      </div>
      <div>
        <ul className="contact">
          <li>Contact Us</li>
          {phoneNumber != null ? (
            <li>
              <a href={`tel:${phoneNumber}`}>
                <img loading="lazy" src={Phone} alt="Phone" />
                {phoneNumber}
              </a>
            </li>
          ) : null}
          {emailAddress != null ? (
            <li>
              <a href={`mailto:${emailAddress}`}>
                <img loading="lazy" src={Email} alt="Email" />
                {emailAddress}
              </a>
            </li>
          ) : null}
        </ul>
        <span className="copyright">
          Copyright &copy; 2022-{new Date().getFullYear()} So-Called Civilized.
          All rights reserved.
        </span>
      </div>
    </div>
  );
}
