import React, { useContext, useEffect, useRef, useState } from "react";
import getApiUrl from "../../logic/apiUrl";
import axios from "axios";
import { CartContext } from "@context/CartContext";
import { calculateSubtotal } from "../../logic/cart";

export default function CartTotal(props) {
  const { cart } = useContext(CartContext);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [total, setTotal] = useState(0);
  const checkoutButtonRef = useRef();
  const { customerInfo, deliveryInfo } = props;

  function makePayment(e) {
    const apiUrl = getApiUrl();

    e.preventDefault();

    const customerObj = {
      full_name: customerInfo.fullName,
      email_address: customerInfo.emailAddress,
      phone_number: customerInfo.phoneNumber,
      address: deliveryInfo.address,
      address2: deliveryInfo.address2,
      city: deliveryInfo.city,
      state: deliveryInfo.state,
      zip_code: deliveryInfo.zipCode,
    };

    // disable checkout button
    checkoutButtonRef.current.disabled = true;
    checkoutButtonRef.current.innerHTML = "<span>Loading...</span>";

    axios
      .post(apiUrl + "/shop/checkout", {
        cart: cart,
        customerInfo: customerObj,
      })
      .then((res) => {
        if (res.data.redirectUrl !== undefined) {
          window.location.replace(res.data.redirectUrl);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // make button available again
        checkoutButtonRef.current.disabled = false;
        checkoutButtonRef.current.innerHTML = "<span>Checkout</span>";
      });
  }

  useEffect(() => {
    setSubtotal(calculateSubtotal(cart));
  }, []);

  // update tax, shipping, and total whenever subtotal changes
  useEffect(() => {
    // calculate tax at 9% and round it to 2 decimal places
    setTax(Math.floor(subtotal * 0.09));
    setShipping(299);
    setTotal(subtotal + tax + shipping);
  }, [subtotal, tax, shipping, total]);
  return (
    <div className="cart-total">
      <h2>Checkout</h2>
      <div>
        <div className="subtotal">
          <span>Subtotal:</span>
          <span>${(subtotal / 100).toFixed(2)}</span>
        </div>
        <div className="tax">
          <span>Tax (9%):</span>
          <span>${(tax / 100).toFixed(2)}</span>
        </div>
        <div className="tax">
          <span>Shipping:</span>
          <span>$2.99</span>
        </div>
        <div className="total">
          <span>Total:</span>
          <span>${(total / 100).toFixed(2)}</span>
        </div>
      </div>
      <button ref={checkoutButtonRef} onClick={makePayment}>
        <span>Checkout</span>
      </button>
    </div>
  );
}
