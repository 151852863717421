import React, { useEffect, useRef } from "react";

export default function ContactInformation(props) {
  const fullNameRef = useRef();
  const emailAddressRef = useRef();
  const phoneNumberRef = useRef();

  function updateInfo() {
    props.setCustomerInfo({
      fullName: fullNameRef.current.value,
      emailAddress: emailAddressRef.current.value,
      phoneNumber: phoneNumberRef.current.value,
    });
  }

  return (
    <div>
      <h2>Contact Information</h2>
      <input
        ref={fullNameRef}
        required
        name="full-name"
        type="text"
        placeholder="Full Name"
        onChange={updateInfo}
      />
      <input
        required
        ref={emailAddressRef}
        name="email-address"
        type="email"
        placeholder="Email Address"
        onChange={updateInfo}
      />
      <input
        ref={phoneNumberRef}
        name="phone-number"
        type="phone"
        placeholder="Phone Number (Optional)"
        onChange={updateInfo}
      />
    </div>
  );
}
