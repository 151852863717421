// packages
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createClient } from "contentful";
import { ContentfulContext } from "@context/ContentfulContext";
import { CartContext } from "@context/CartContext";

// styling
import "./App.css";

// components
import NavigationBar from "@components/NavigationBar";
import Footer from "@components/Footer";

// admin console
import Admin from "./Admin Console/Admin";

// pages
import Landing from "@pages/Landing";
import About from "@pages/About";
import Shop from "@pages/Shop";
import Blog from "@pages/Blog";
import Post from "@pages/Post";
import Checkout from "./Checkout/Checkout";
import Legal from "@pages/Legal";
import Error from "@pages/Error";
import Launch from "@pages/Launch";

function App() {
  const [cart, setCart] = useState([]); // will store the ID of each item user is interested in purchasing
  //const [beforeLaunch, setBeforeLaunch] = useState(true);
  const contentfulClient = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_KEY,
  });

  useEffect(() => {
    if (cart.length !== 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  useEffect(() => {
    if (cart.length === 0) {
      const cartName =
        window.location.hostname === "localhost"
          ? "local-scc-cart"
          : "scc-cart";
      const localCart = JSON.parse(localStorage.getItem(cartName));
      if (localCart) {
        setCart(localCart);
      }
    }
  }, []);

  // LAUNCH CODE
  // May be used in future
  // useEffect(() => {
  //   setInterval(() => {
  //     // set launch date to MDT timezone
  //     let launchDate = new Date("August 26, 2023 10:50:00").toLocaleString(
  //       "en-US",
  //       { timeZone: "America/Denver" }
  //     );

  //     let currentDate = new Date().toLocaleString("en-US", {
  //       timeZone: "America/Denver",
  //     });

  //     setBeforeLaunch(new Date(currentDate) - new Date(launchDate) <= 0);
  //   }, 200);
  // }, []);

  // if (beforeLaunch) {
  //   return <Launch />;
  // }

  return (
    <div className="App bg-gray-950">
      <Router>
        <ContentfulContext.Provider value={contentfulClient}>
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route
              path="/*"
              element={
                <CartContext.Provider value={{ cart, setCart }}>
                  <NavigationBar />
                  <div className="page-container">
                    <Routes>
                      <Route path="/" element={<Landing />} />
                      <Route path="/shop" element={<Shop />} />
                      <Route path="/blog" element={<Blog />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/checkout" element={<Checkout />} />
                      <Route path="/blog/post/:postID" element={<Post />} />
                      <Route path="/legal/:document" element={<Legal />} />
                      <Route path="*" element={<Error />} />
                    </Routes>
                  </div>
                  <Footer />
                </CartContext.Provider>
              }
            />
          </Routes>
        </ContentfulContext.Provider>
      </Router>
    </div>
  );
}

export default App;
