// packages
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentfulContext } from "@context/ContentfulContext";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Swal from "sweetalert2";
import SwalUtils from "@components/SwalUtils";

// components
import Hero from "@components/Hero";

export default function Home() {
  const contentfulClient = useContext(ContentfulContext);
  const [posts, setPosts] = useState(null);
  const [hero, setHero] = useState(null);

  useEffect(() => {
    // get hero information
    contentfulClient
      .getEntries({ content_type: "bannerContent" })
      .then((response) => {
        response.items.forEach((item) => {
          if (item.fields.page === "Blog") {
            setHero(item.fields);
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          ...SwalUtils.universal,
          ...{
            title: "Error",
            text: "Could not connect to content repository. Please try again later. Error printed in console.",
            icon: "error",
          },
        });
        console.log(err);
      });

    // get blog posts
    contentfulClient
      .getEntries({ content_type: "wallPost", order: "-sys.createdAt" })
      .then((response) => {
        setPosts(response.items);
      })
      .catch((err) => {
        Swal.fire({
          ...SwalUtils.universal,
          ...{
            title: "Error",
            text: "Could not connect to content repository. Please try again later. Error printed in console.",
            icon: "error",
          },
        });
        console.log(err);
      });
  }, [contentfulClient]);

  if (posts === null) {
    return null;
  }

  return (
    <div className="home">
      {
        // avoid page break when hero not set yet
        hero === null ? null : (
          <Hero
            backgroundImage={hero.backgroundImage.fields.file.url}
            headline={hero.mainHeadline}
            subheadline={hero.subheadline}
          />
        )
      }
      <div>
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            100: 1,
            350: 2,
            750: 3,
            900: 4,
            1300: 5,
          }}
        >
          <Masonry gutter="20px" className="p-5">
            {posts.map((post, key) => {
              let thumbnailURL = post.fields.thumbnail.fields.file.url;
              let id = post.sys.id;

              return (
                <Link
                  to={"/blog/post/" + id}
                  className="group block overflow-hidden bg-slate-900"
                >
                  <div className="overflow-hidden">
                    <img
                      alt={post.fields.postTitle}
                      loading="eager"
                      src={thumbnailURL}
                      className="transition-transform group-hover:scale-110"
                    />
                  </div>

                  <div className="p-3 text-left md:p-5 lg:p-8">
                    <span className="mb-2 block text-xl font-bold md:mb-4 md:text-2xl">
                      {post.fields.postTitle}
                    </span>
                    <span className="text-slate-200">
                      {post.fields.shortDescription}
                    </span>
                    <span className="mt-6 block text-slate-500">
                      {new Date(post.sys.createdAt).toLocaleDateString()}
                    </span>
                  </div>
                </Link>
              );
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
}
