function calculateSubtotal(cart) {
  let subtotal = 0;

  // create subtotal and round it to 2 decimal places
  cart.forEach((item) => {
    subtotal += item.product.fields.price * item.quantity * 100;
  });

  return subtotal;
}

function removeFromCart(cart, itemId) {
  const newCart = cart.filter((item) => item.id !== itemId);
  return newCart;
}

module.exports = { calculateSubtotal, removeFromCart };
