// packages
import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "@context/CartContext";

// styling
import "@css/ProductCard.css";

// images/icons
import Add from "@images/icons/add.svg";
import Remove from "@images/icons/remove.svg";
import Cancel from "@images/icons/close.svg";
import Confirm from "@images/icons/confirm.svg";
import ChevronLeft from "@images/icons/chevron-left.svg";
import ChevronRight from "@images/icons/chevron-right.svg";

export default function ProductCard(props) {
  const { cart, setCart } = useContext(CartContext);
  const [itemImages, setItemImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectingDetails, setSelectingDetails] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState(null);
  const [itemInCart, setItemInCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function isItemInCart() {
    for (let item in cart) {
      if (cart[item].product.sys.id === props.product.sys.id) {
        return true;
      }
    }

    return false;
  }

  function addToCart() {
    if (!isItemInCart()) {
      let item = {
        product: props.product,
        quantity: parseInt(quantity),
        size: size,
      };
      setCart((cart) => [...cart, item]);
      setItemInCart(true);
      setSelectingDetails(false);
    }
  }

  function removeFromCart(props) {
    if (isItemInCart()) {
      setCart((cart) => {
        return cart.filter(
          (item) => item.product.sys.id !== props.product.sys.id,
        );
      });
      setItemInCart(false);
    }
  }

  function nextImage() {
    if (currentImageIndex < itemImages.length - 1) {
      setIsLoading(true);
      setCurrentImageIndex(currentImageIndex + 1);
    }
  }

  function prevImage() {
    if (currentImageIndex > 0) {
      setIsLoading(true);
      setCurrentImageIndex(currentImageIndex - 1);
    }
  }

  useEffect(() => {
    setItemInCart(isItemInCart());

    // check item type and set size if necessary
    if (
      props.product.fields.itemType === "Shirt" ||
      props.product.fields.itemType === "Hoodie"
    ) {
      setSize("XL");
    }

    setItemImages([]);
    if (props.product.fields.itemImages) {
      props.product.fields.itemImages.forEach((image) => {
        // ensure image isn't already in array
        if (!itemImages.includes(image.fields.file.url)) {
          setItemImages((itemImages) => [...itemImages, image.fields.file.url]);
        }
      });
    }

    setCurrentImageIndex(0);
  }, []);

  if (itemImages === null) {
    return null;
  }

  return (
    <div className="product-wrapper">
      <div className="product bg-slate-900">
        <div className="item-image-container">
          <div className="item-images" style={{ opacity: isLoading ? 0.5 : 1 }}>
            <img
              onLoad={() => {
                setIsLoading(false);
              }}
              onClick={() => {
                props.setItemPreviewShowing(true);
                props.setItemPreviewImage(itemImages[currentImageIndex]);
              }}
              draggable="false"
              className="product-image"
              alt="product"
              loading="eager"
              src={itemImages[currentImageIndex]}
            />
          </div>
          {/* next and prev buttons */}
          {!isLoading && itemImages.length > 1 && (
            <>
              {currentImageIndex !== 0 && (
                <div className="prev-image-wrapper button-wrapper">
                  <img
                    draggable="false"
                    alt="Previous"
                    src={ChevronLeft}
                    onClick={prevImage}
                    className="prev-image-button"
                  />
                </div>
              )}
              {currentImageIndex !== itemImages.length - 1 && (
                <div className="next-image-wrapper button-wrapper">
                  <img
                    draggable="false"
                    alt="Next"
                    src={ChevronRight}
                    onClick={nextImage}
                    className="next-image-button"
                  />
                </div>
              )}
            </>
          )}
        </div>

        <span className="product-name text-xl">
          {props.product.fields.itemName}
        </span>
        <span className="product-info text-xs">
          <span className="type">{props.product.fields.itemType}</span>
          <span className="separator">/</span>
          <span className="price">${props.product.fields.price}</span>
        </span>

        {itemInCart ? (
          <button
            onClick={() => {
              removeFromCart(props);
            }}
            className="cart-button in-cart text-sm"
          >
            Remove <img alt="Remove Item from Cart" src={Remove} />
          </button>
        ) : !selectingDetails ? (
          <button
            onClick={() => {
              setSelectingDetails(true);
            }}
            className="cart-button text-sm"
          >
            Add to Cart <img alt="Add Item to Cart" src={Add} />
          </button>
        ) : (
          <div className="details-selector text-sm">
            <div>
              <label>Quantity</label>
              <input
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
                type="number"
                min="1"
                max="100"
                defaultValue={1}
              />
            </div>
            {props.product.fields.itemType === "Shirt" ||
            props.product.fields.itemType === "Hoodie" ? (
              <div>
                <label>Size</label>
                <select
                  onChange={(e) => {
                    setSize(e.target.value);
                  }}
                  defaultValue={"XL"}
                >
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                </select>
              </div>
            ) : null}

            <div className="confirm-cancel-buttons">
              <button
                onClick={() => {
                  setSelectingDetails(false);
                }}
                className="cart-button cancel"
              >
                <img alt="Cancel Size and Quantity Selection" src={Cancel} />
              </button>
              <button
                onClick={() => {
                  addToCart();
                }}
                className="cart-button confirm"
              >
                <img alt="Add Item to Cart" src={Confirm} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
