// packages
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ContentfulContext } from "@context/ContentfulContext";
import { CartContext } from "@context/CartContext";
import ReactGA from "react-ga4";
import Swal from "sweetalert2";
import SwalUtils from "@components/SwalUtils";

// styling
import "@css/NavigationBar.css";

// images/icons
import LargeLogoWhite from "@images/branding/large-logo-white.png"; // oddly enough this is our mobile logo
import MediumLogoWhite from "@images/branding/medium-logo-white.png"; // and this is our desktop/tablet logo
import ShoppingBag from "@images/icons/shopping-bag.svg";
import Hamburger from "@images/icons/hamburger.svg";
import Close from "@images/icons/close.svg";

export default function NavigationBar() {
  const contentfulClient = useContext(ContentfulContext);
  const { cart } = useContext(CartContext);
  const [logo, setLogo] = useState(LargeLogoWhite);
  const [blueBannerContent, setBlueBannerContent] =
    useState("Loading banner...");
  const [navExpanded, setNavExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function selectionMade() {
    setNavExpanded(false);
  }

  function chooseLogo() {
    if (window.innerWidth > 800) {
      setLogo(MediumLogoWhite);
    } else {
      setLogo(LargeLogoWhite);
    }
  }

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);

    setNavExpanded(false);
    contentfulClient
      .getEntries({ content_type: "generalInformation" })
      .then((res) => {
        setBlueBannerContent(res.items[0].fields.notificationBannerContent);
      })
      .catch((err) => {
        Swal.fire({
          ...SwalUtils.universal,
          ...{
            title: "Error",
            text: "Could not connect to content repository. Please try again later. Error printed in console.",
            icon: "error",
          },
        });
        console.log(err);
      });

    // chose correct logo based on screen size
    window.addEventListener("resize", () => {
      chooseLogo();
    });

    chooseLogo();
  }, [contentfulClient]);

  // on url change (navigation or direct)
  useEffect(() => {
    window.scrollTo(0, 0);

    // report pageview in production only
    if (
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "stage.so-calledcivilized.com"
    ) {
      ReactGA.send("pageview");
    }
  }, [location]);

  return (
    <div className={"navigation-bar " + (navExpanded ? "expanded" : "")}>
      <div className="branding">
        <img
          draggable="false"
          onClick={() => {
            navigate("/");
          }}
          src={logo}
          alt="Medium Logo"
        />
      </div>
      <Link to="/checkout" className="cart-area">
        <img draggable="false" src={ShoppingBag} alt="Shopping Bag" />
        <span
          className={"item-alert " + (cart.length === 0 ? "disabled" : "")}
        ></span>
      </Link>
      <div className="nav-mobile-toggle">
        <img
          draggable="false"
          onClick={() => setNavExpanded(!navExpanded)}
          src={navExpanded ? Close : Hamburger}
          alt="Toggle Navigation Bar"
        />
      </div>
      <div className="navigation-wrapper">
        <div className="navigation">
          <Link onClick={selectionMade} to="/shop">
            Shop
          </Link>
          <Link onClick={selectionMade} to="/blog">
            Blog
          </Link>
          <Link onClick={selectionMade} to="/about">
            About
          </Link>
        </div>
      </div>
      <div className="notification-banner">
        <span className="banner-text">{blueBannerContent}</span>
      </div>
    </div>
  );
}
