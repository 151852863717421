import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminContext } from "../AdminContext";
import getApiUrl from "../logic/apiUrl";
import axios from "axios";
import Swal from "sweetalert2";
import SwalUtils from "@components/SwalUtils";

export default function Order(props) {
  const { usernamePair } = useContext(AdminContext);
  const { order } = props;
  const [orderStatusColor, setOrderStatusColor] = useState("text-slate-400");
  const [orderDeleted, setOrderDeleted] = useState(false);

  const apiUrl = getApiUrl();

  useEffect(() => {
    switch (order.order_status) {
      case "pending":
        setOrderStatusColor("text-yellow-600");
        break;
      case "confirmed":
        setOrderStatusColor("text-green-600");
        break;
      case "shipped":
        setOrderStatusColor("text-blue-500");
        break;
      case "delivered":
        setOrderStatusColor("text-slate-400");
        break;
      case "canceled":
        setOrderStatusColor("text-red-500");
        break;
      default:
        setOrderStatusColor("text-slate-900");
    }
  }, []);

  return (
    <div className="my-6 bg-white p-8 shadow-md">
      {!orderDeleted ? (
        <>
          <div className="mb-4 flex flex-row justify-between border-b-2 border-slate-200 pb-2">
            <span className="block text-2xl font-bold">
              {order.customer_information.full_name}
            </span>
            <span className={"block text-xl " + orderStatusColor}>
              {order.order_status.charAt(0).toUpperCase() +
                order.order_status.slice(1) || "None"}
            </span>
          </div>

          <span className="block text-xl">
            <b>Order ID:</b>{" "}
            <Link
              to={"/order_status?orderId=" + order._id}
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {order._id}
            </Link>
          </span>
          <span className="text-xl">
            <span>
              {order.tracking_number !== null ? (
                <span className="font-medium">
                  Tracking Number:{" "}
                  <a
                    href={
                      "https://www.ups.com/track?loc=en_US&tracknum=" +
                      order.tracking_number +
                      "&requester=ST/"
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {order.tracking_number}
                  </a>
                </span>
              ) : (
                <span className="text-slate-400">No Tracking Number</span>
              )}
            </span>
          </span>
          <br />
          <div className="mt-5 border-t-2 border-slate-200 pt-10">
            {order.items.map((item, key) => {
              return (
                <div className="mb-5 flex flex-row" key={key}>
                  <img
                    src={item.product.fields.itemImages[0].fields.file.url}
                    className="mr-5 inline-block h-28 w-28 rounded-2xl object-cover shadow-md"
                    alt={item.product.fields.itemName}
                  />
                  <div className="flex flex-col justify-center">
                    <span className="font-bold">
                      {item.product.fields.itemName}
                    </span>
                    <span>Quantity: {item.quantity}</span>
                    <span>{item.size && `Size: ${item.size}`} </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-10 border-t-2 border-slate-200 pt-3">
            {usernamePair.username === "ckmontesano" && (
              <span
                onClick={deleteOrder}
                className="cursor-pointer text-red-600 hover:underline"
              >
                Delete Order
              </span>
            )}
          </div>
        </>
      ) : (
        <span className="text-slate-500">Order was deleted.</span>
      )}
    </div>
  );

  async function deleteOrder() {
    Swal.fire({
      ...SwalUtils.universal,
      ...SwalUtils.types["deletePrompt"],
      ...{
        title: "Are you sure you want to permanently delete this order?",
        text: "This cannot be undone.",
        icon: "warning",
        showCancelButton: true,
      },
    }).then(async (deleteItem) => {
      console.log("API URL", apiUrl);
      if (deleteItem.isConfirmed) {
        try {
          const response = await axios.delete(apiUrl + "/admin/delete_order", {
            data: { orderId: order._id },
          });
          if (response.data.success) setOrderDeleted(true);
        } catch (error) {
          console.error("Error deleting order: ", error);
        }
      }
    });
  }
}
