import React, { useState, useRef, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { sha256 } from "js-sha256";
import { AdminContext } from "./AdminContext";
import getApiUrl from "./logic/apiUrl";

export default function Login() {
  const { loginPair, usernamePair } = useContext(AdminContext);
  const { setLoggedIn } = loginPair;
  const { username, setUsername } = usernamePair;
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const LoginButtonRef = useRef();

  const apiUrl = getApiUrl();

  function attemptLogin(e) {
    e.preventDefault();

    LoginButtonRef.current.disabled = true;
    LoginButtonRef.current.innerHTML = "Logging in...";

    axios({
      method: "post",
      url: apiUrl + "/admin/verify_credentials",
      data: {
        username: username,
        password: sha256(password),
      },
    })
      .then((res) => {
        if (res.data.login_success) {
          setLoggedIn(true);
          window.localStorage.setItem("username", username);
          window.localStorage.setItem("loggedIn", true);
        } else {
          setError("Incorrect username or password.");
        }
      })
      .catch((err) => {
        setError("Something went wrong. Please notify me.");
        console.log(err);
      })
      .finally(() => {
        setPassword("");
        LoginButtonRef.current.disabled = false;
        LoginButtonRef.current.innerHTML = "Log In";
      });
  }

  useEffect(() => {
    if (window.localStorage.getItem("loggedIn") === "true") {
      setLoggedIn(true);
      setUsername(window.localStorage.getItem("username"));
    }
  });

  return (
    <div className="px-8 py-10 text-center lg:px-28 lg:py-16">
      <form
        onSubmit={attemptLogin}
        className="inline-flex flex-col gap-3 bg-slate-100 p-10 pb-8 shadow-lg"
      >
        <input
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          type="text"
          placeholder="Username"
          className="rounded-md bg-slate-200 p-2"
          maxLength={50}
        />
        <input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          placeholder="Password"
          className="rounded-md bg-slate-200 p-2"
          maxLength={100}
        />
        <button
          className="mt-4 rounded-md bg-slate-800 p-1.5 font-bold text-slate-100 transition hover:bg-slate-700"
          ref={LoginButtonRef}
        >
          Log In
        </button>
        <p className="text-sm font-medium text-red-600">{error}</p>
      </form>
      <Link
        to="/"
        className="group mt-5 flex justify-center gap-1 text-blue-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
          />
        </svg>
        <span className="group-hover:underline">
          Back to So-Called Civilized
        </span>
      </Link>
    </div>
  );
}
