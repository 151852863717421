// dependencies
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentfulContext } from "@context/ContentfulContext";
import Swal from "sweetalert2";
import SwalUtils from "@components/SwalUtils";

// images/icons
import KyleChinn from "@images/about-kyle.jpeg";

export default function Landing() {
  const contentfulClient = useContext(ContentfulContext);
  const [backgroundImg, setBackgroundImg] = useState(null);
  const heroBackgroundStyle = {
    backgroundImage: `url(${backgroundImg})`,
  };

  useEffect(() => {
    contentfulClient
      .getEntries({ content_type: "generalInformation" })
      .then((res) => {
        setBackgroundImg(
          res.items[0].fields.landingPageBackgroundImages[0].fields.file.url,
        );
      })
      .catch((err) => {
        Swal.fire({
          ...SwalUtils.universal,
          ...{
            title: "Error",
            text: "Could not connect to content repository. Please try again later. Error printed in console.",
            icon: "error",
          },
        });
        console.log(err);
      });
  }, []);

  return (
    <div className="landing">
      <div className="relative -mt-5 min-h-72 border-b-4 border-black lg:min-h-96">
        <div className="absolute z-10 h-full w-full flex-col content-center justify-center bg-slate-950/50">
          <h1 className="font-banco text-4xl lg:text-5xl">
            Challenge the Norm
          </h1>
          <p className="font-banco text-xl text-slate-400 lg:text-3xl">
            Break Boundaries with Every Thread
          </p>
          <Link
            to="/shop"
            className="text-white-400 mt-8 inline-flex items-center justify-center gap-2 rounded-lg bg-indigo-700 px-6 py-3 transition hover:bg-indigo-800 lg:text-lg"
          >
            <span>Shop Apparel</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </Link>
        </div>
        <div
          className="absolute h-full w-full bg-cover bg-center"
          style={heroBackgroundStyle}
        >
          {/* hero background */}
        </div>
      </div>
      <div className="-mb-24 lg:flex">
        <div className="flex flex-1 flex-col justify-center p-12 text-left">
          <h2 className="mb-6 text-left">
            <span className="text-2xl text-slate-400">What is...</span>
            <span className="block text-4xl font-black">
              So-Called Civilized?
            </span>
          </h2>
          <p className="text-lg text-slate-200">
            So-Called Civilized was born out of a love of being different -
            Being who you are and not giving a f*ck about what others think.
            It’s about living life how you want to live it, even if it means
            going against society’s norms.
          </p>
          <Link
            to="/about"
            className="mt-6 inline-flex items-center gap-1 text-xl text-indigo-500 hover:text-indigo-400"
          >
            Learn More
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </Link>
        </div>
        <div className="flex-1 border-l-4 border-black">
          <img
            src={KyleChinn}
            className="hidden h-full w-full object-cover lg:block"
            draggable={false}
            alt="Kyle Chinn, Founder of So-Called Civilized"
          />
        </div>
      </div>
    </div>
  );
}
