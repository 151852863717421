import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContentfulContext } from "@context/ContentfulContext";
import Marquee from "react-fast-marquee";

export default function About() {
  const contentfulClient = useContext(ContentfulContext);
  const [pageContent, setPageContent] = useState(null);
  const [aboutText, setAboutText] = useState("");
  const [images, setImages] = useState([]);
  const [blogPreviewImage, setBlogPreviewImage] = useState(null);
  const [shopPreviewImage, setShopPreviewImage] = useState(null);

  const blogPreviewStyle = {
    backgroundImage: `url(${blogPreviewImage})`,
  };

  const shopPreviewStyle = {
    backgroundImage: `url(${shopPreviewImage})`,
  };

  useEffect(() => {
    contentfulClient.getEntry("56J6cIfU9M1kE2cqP6V4co").then((response) => {
      setPageContent(response.fields);
      setBlogPreviewImage(response.fields.blogPreviewImage.fields.file.url);
      setShopPreviewImage(response.fields.shopPreviewImage.fields.file.url);

      setAboutText(response.fields.aboutUsText);
      setImages(response.fields.photos);
    });
  }, [contentfulClient]);

  return (
    <div className="-mt-6">
      {pageContent !== null && (
        <>
          <Marquee speed="20" autoFill={true}>
            {images &&
              images.length !== 0 &&
              images.map((image, key) => {
                return (
                  <img
                    src={image.fields.file.url}
                    className="h-56 border-r-4 border-gray-950 object-cover lg:h-96"
                    alt="SCC Gallery"
                    draggable="false"
                    key={key}
                  />
                );
              })}
          </Marquee>
          <div className="px-14 py-8 text-center md:px-28 md:py-16 md:text-left">
            <div className="mb-8 mt-1 flex flex-col content-center gap-4 md:flex-row md:gap-6">
              <img
                src={pageContent.profilePhoto.fields.file.url}
                className="position-center m-auto h-28 w-28 rounded-full border-4 border-slate-600 object-cover object-top md:m-0"
                alt="Kyle Chin, Founder of So-Called Civilized"
              />
              <div className="md:content-center">
                <span className="block text-3xl">Kyle Chin</span>
                <span className="block text-xl text-slate-400">Founder</span>
                <span>{pageContent.bio}</span>
                <span>
                  <a
                    href="https://www.instagram.com/kyle.chin/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-indigo-400 hover:text-indigo-500"
                  >
                    @kyle.chin
                  </a>
                </span>
              </div>
            </div>
            <h1 className="mb-6 text-center text-3xl text-slate-400 md:text-left md:text-4xl">
              What does it mean to be{" "}
              <span className="block md:inline">
                <span className="font-banco text-white">
                  So-Called Civilized
                </span>
                ?
              </span>
            </h1>
            <span className="text-xl">{aboutText}</span>
            <div className="mt-14">
              <h2 className="mb-5 text-center text-2xl text-slate-500 md:text-3xl">
                Check out more...
              </h2>
              <div className="flex flex-col justify-center gap-8 md:flex-row md:gap-12">
                <Link to="/blog">
                  <div
                    style={blogPreviewStyle}
                    className="h-56 w-full rounded-md bg-cover bg-top shadow-2xl shadow-slate-950 hover:border-4 hover:border-white md:w-56"
                  >
                    <div className="flex h-full w-full justify-center bg-black/50 backdrop-blur-sm">
                      <span className="font-banco content-center text-6xl">
                        Blog
                      </span>
                    </div>
                  </div>
                </Link>
                <Link to="/shop">
                  <div
                    style={shopPreviewStyle}
                    className="h-56 w-full rounded-md bg-cover bg-top shadow-2xl shadow-slate-950 hover:border-4 hover:border-white md:w-56"
                  >
                    <div className="flex h-full w-full justify-center bg-black/50 backdrop-blur-sm">
                      <span className="font-banco content-center text-6xl">
                        Shop
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
