// packages
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentfulContext } from "@context/ContentfulContext";

export default function Legal() {
  const contentfulClient = useContext(ContentfulContext);
  const { document } = useParams();
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    // get legal document content
    contentfulClient.getEntries({ content_type: "legal" }).then((response) => {
      let query;
      if (document === "terms-of-service") {
        query = "Terms of Service";
      } else if (document === "privacy-policy") {
        query = "Privacy Policy";
      } else if (document === "content-disclosure") {
        query = "Content Disclosure";
      }

      response.items.forEach((item) => {
        if (item.fields.documentName === query) {
          setTitle(item.fields.documentName);
          setContent(item.fields.content);
        }
      });
    });
  }, [document, contentfulClient]);

  return (
    <div className="basic-padding-container mt-10">
      <h1 className="text-3xl">{title}</h1>
      <p>{content}</p>
    </div>
  );
}
